import { IpinfoService } from 'src/app/services/ipinfo.service';

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'contabil',
    loadChildren: () => import('./Components/contabil/contabil.module').then((m) => m.ContabilModule),
    resolve: { decodeSessionUser: IpinfoService }
  },
  {
    path: 'parametrizacao',
    loadChildren: () => import('./Components/parametrizacao/parametrizacao.module').then((m) => m.ParametrizacaoModule)
  },{
    path: 'acompanhamento',
    loadChildren: () => import('./Components/acompanhamento-sispag-deb/acompanhamento-sispag-deb.module').then((m) => m.AcompanhamentoSispagDebModule)
  },
  {
    path: 'logs',
    loadChildren: () => import('./Components/logs/logs.module').then((m) => m.LogsModule)
  },
  {
    path: 'viagens',
    loadChildren: () => import('./Components/viagens/viagens.module').then((m) => m.ViagensModule),
    resolve: { decodeSessionUser: IpinfoService }
  },
  {
    path: 'cartao-credito',
    loadChildren: () => import('./Components/cartao-credito/cartao-credito.module').then((m) => m.CartaoCreditoModule),
    resolve: { decodeSessionUser: IpinfoService }
  },
  {
    path: 'home',
    loadChildren: () => import('./Components/home/home.module').then((m) => m.HomeModule),
    resolve: { decodeSessionUser: IpinfoService }
  },
  {
    path: '**',
    redirectTo: 'home'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
