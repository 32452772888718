import { Component, OnInit, Renderer2 } from '@angular/core';
import { AuthService } from './services/auth.service';
import { LoaderService } from './services/loader.service';
import { UserLogEntryService } from './services/user-log-entry-service.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'stexa-frontend';
  isExpanded = false;

  constructor(
    private authService: AuthService,
    private loaderService: LoaderService,
    private renderer: Renderer2,
    private userLogEntryService: UserLogEntryService
  ) { }

  ngOnInit(): void {

    this.loaderService.show();
    this.authService.configureLoginSingleSignOn()
      .then(
        (accountInfo) => {

          if (accountInfo) {
            this.loadInitialData();
            this.userLogEntryService.initializeUserLogEntryDto();

          }
          else
            this.authService.logout();

          this.loaderService.hide();

        }
      )
  }

  loadInitialData(): void {
    const hostname = window.location.hostname;

    if (hostname === 'localhost') {
      this.renderer.setAttribute(document.body, 'data-env', 'LOCAL');
    } else if (hostname.endsWith('.dev.people-culture-sla.devtest.gf.aws.scania.com')) {
      this.renderer.setAttribute(document.body, 'data-env', 'DEV');
    } else if (hostname.endsWith('.test.people-culture-sla.devtest.gf.aws.scania.com')) {
      this.renderer.setAttribute(document.body, 'data-env', 'ACP');
    }
  }

  onExpand(): void {
    this.isExpanded = !this.isExpanded;
  }


}
