import { Injectable } from '@angular/core';
import { EnvironmentsEnum } from '../_shared/environments.enum';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() { }

  public getWebApiEndpoinStexa() {
    return window.location.host.indexOf('localhost') > -1 ? 'https://dev-stexa-api.br.scania.com/api' :
           window.location.host.indexOf('dev.') > -1 ? 'https://dev-stexa-api.br.scania.com/api' :
           window.location.host.indexOf('test.') > -1 ? 'https://acp-stexa-api.br.scania.com/api' :
                                                       'https://stexa-api.br.scania.com/api';
  }

  public getWebApiEndpoinVisa() {
    return window.location.host.indexOf('localhost') > -1 ? 'https://acp-newvisa.br.scania.com/api' :
           window.location.host.indexOf('dev.') > -1 ? 'https://acp-newvisa.br.scania.com/api' :
           window.location.host.indexOf('test.') > -1 ? 'https://acp-newvisa.br.scania.com/api' :
                                                       'https://newvisa.br.scania.com/api';
  }

  public getEnviroment(): EnvironmentsEnum {

    const url = window.location.host;

    if (url.indexOf('localhost') > -1)
      return EnvironmentsEnum.local;
    else if (url.indexOf('dev.') > -1)
      return EnvironmentsEnum.development;
    else if (url.indexOf('test.') > -1)
      return EnvironmentsEnum.acceptance;
    else
      return EnvironmentsEnum.production;


  }

}
