import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { IPinfo } from 'src/app/_models/IPinfo';
import { IpinfoService } from 'src/app/services/ipinfo.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';

@Component({
  selector: 'app-ip-info',
  template: `<div *ngIf="ipInfo$ | async as data"></div>`
})
export class IpInfoComponent implements OnInit{

  ipInfo$: Observable<IPinfo>;

  constructor(private Ipinfo: IpinfoService, private localStorage: LocalStorageService){}

  ngOnInit(): void {
    this.ipInfo$ = this.Ipinfo.getIpiInfo();
    this.ipInfo$.subscribe(ipInfo => {
      this.localStorage.set('ipInfo', ipInfo);
      // console.log(ipInfo);
    });
  }
}
