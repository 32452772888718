import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';




import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HeaderComponent } from './Components/header/header.component';
import { AppComponent } from './app.component';
import { FooterComponent } from './Components/footer/footer.component';
import { MainComponent } from './Components/main/main.component';


import { LoaderService } from './services/loader.service';
import { LoaderInterceptor } from './services/loader.service.interceptor';
import { IpInfoComponent } from './Components/ip-info/ip-info.component';


import { LoaderModule } from 'src/modules/loader/loader.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { AppRoutingModule } from './app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgxLoadingModule } from "ngx-loading";
import { FormsModule } from '@angular/forms';

import { DatePipe, registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';

registerLocaleData(localePt);



@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    MainComponent,
    IpInfoComponent

  ],
  imports: [
    AppRoutingModule,
    FontAwesomeModule,
    FormsModule,
    HttpClientModule,
    LoaderModule,
    BrowserModule,
    NgxLoadingModule.forRoot({}),
  ],
  providers: [
    LoaderService,
    DatePipe,
    {
    provide: HTTP_INTERCEPTORS,
    useClass: LoaderInterceptor,
    multi: true,
    },
    { provide: LOCALE_ID, useValue: 'pt-BR' }],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class AppModule { }
