import { IpinfoService } from 'src/app/services/ipinfo.service';

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'contabil',
    loadChildren: () => import('./Components/contabil/contabil.module').then((m) => m.ContabilModule),
    resolve: { decodeSessionUser: IpinfoService }
  },
  {
    path: 'parametrizacao',
    loadChildren: () => import('./Components/parametrizacao/parametrizacao.module').then((m) => m.ParametrizacaoModule)
  },{
    path: 'acompanhamento',
    loadChildren: () => import('./Components/acompanhamento-sispag-deb/acompanhamento-sispag-deb.module').then((m) => m.AcompanhamentoSispagDebModule)
  },
  {
    path: 'logs',
    loadChildren: () => import('./Components/logs/logs.module').then((m) => m.LogsModule)
  },
  {
    path: 'notificacao-parametrizacao',
    loadChildren: () => import('./Components/viagens/notificacao/notificacao-parametrizacao/notificacao-parametrizacao.module')
                              .then((m) => m.NotificacaoParametrizacaoModule),
    resolve: { decodeSessionUser: IpinfoService }
  },
  {
    path: 'notificacao-relatorios',
    loadChildren: () => import('./Components/viagens/relatorios/relatorios.module').then((m) => m.RelatoriosModule),
    resolve: { decodeSessionUser: IpinfoService }
  },
  {
    path: 'cartao-credito',
    loadChildren: () => import('./Components/cartao-credito/cartao-credito.module').then((m) => m.CartaoCreditoModule),
    resolve: { decodeSessionUser: IpinfoService }
  },
  {
    path: 'usuario',
    loadChildren: () => import('./Components/user/user.module').then((m) => m.UserModule),
    resolve: { decodeSessionUser: IpinfoService }
  },
  {
    path: 'home',
    loadChildren: () => import('./Components/home/home.module').then((m) => m.HomeModule),
    resolve: { decodeSessionUser: IpinfoService },
    canActivate: [ MsalGuard ]
  },
  {
    path: '**',
    redirectTo: 'home'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
