<div class="tds-footer">
  <tds-footer>
    <div slot="start">
      <tds-footer-group>
        <tds-footer-item>
        </tds-footer-item>
        <tds-footer-item>
        </tds-footer-item>
        <tds-footer-item>
        </tds-footer-item>
        <tds-footer-item>
        </tds-footer-item>
      </tds-footer-group>
    </div>
    <div slot="end">
      <tds-footer-group>
        <tds-footer-item>
          <a href="https://www.facebook.com/scaniagroup" target="_blank"><fa-icon class="fa-16x" [icon]="['fab', 'facebook']">></fa-icon></a>
        </tds-footer-item>
        <tds-footer-item>
          <a href="https://www.instagram.com/scaniagroup" target="_blank"><fa-icon class="fa-16x" [icon]="['fab', 'instagram']">></fa-icon></a>
        </tds-footer-item>
        <tds-footer-item>
          <a href="https://www.linkedin.com/company/scania/" target="_blank"><fa-icon class="fa-16x" [icon]="['fab', 'linkedin']">></fa-icon></a>
        </tds-footer-item>
        <tds-footer-item>
          <a href="https://twitter.com/scaniagroup" target="_blank"><fa-icon class="fa-16x" [icon]="['fab', 'x-twitter']">></fa-icon></a>
        </tds-footer-item>
        <tds-footer-item>
          <a href="https://www.youtube.com/user/ScaniaGroup" target="_blank"><fa-icon class="fa-16x" [icon]="['fab', 'youtube']">></fa-icon></a>
        </tds-footer-item>
      </tds-footer-group>
    </div>
  </tds-footer>
</div>
