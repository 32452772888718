// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    clientId: "204c95f9-fa23-4e9f-86de-5633275657dc",
    authUrl: "https://login.microsoftonline.com/3bc062e4-ac9d-4c17-b4dd-3aad637ff1ac",
    redirect: "https://stexa.dev.people-culture-sla.devtest.gf.aws.scania.com",
    interceptor: "api://204c95f9-fa23-4e9f-86de-5633275657dc/stexa",
    apiUrl: "https://dev-stexa-api.br.scania.com/api",
    storage: {
      stexaRolesUsuario: "stexaRolesUsuario",
      stexaUsername: "stexaUsername",
    }
  };

  /*
   * For easier debugging in development mode, you can import the following file
   * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
   *
   * This import should be commented out in production mode because it will have a negative impact
   * on performance if an error is thrown.
   */
  // import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
