import { Component } from '@angular/core';
import { RolesEnum } from 'src/app/_shared/roles.enum';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  rolesEnum: typeof RolesEnum = RolesEnum;

  constructor(
    private authService: AuthService
  ) {

  }

  checkUserRoles(roles: string[]): boolean {
    return this.authService.checkUserRoles(roles);
  }


  sair(): void {
    this.authService.logout();
  }

}
