<tds-header>
  <!-- TODO setting aria-expanded="true" on the hamburger button does not work, as it is not
        copied to the button element -->
  <tds-header-hamburger
    id="demo-hamburger"
    onclick="demoSideMenu.open = true;demoHamburger.setAttribute('aria-expanded', true);"
    aria-label="Open application drawer"
    aria-haspopup="true"
    aria-expanded="false"
  ></tds-header-hamburger>

  <tds-header-title><a href="/home">STEXA</a></tds-header-title>

  <tds-header-dropdown>
    <span slot="label">Contábil</span>
    <tds-header-dropdown-list>
      <tds-header-dropdown-list-item>
        <a href="/parametrizacao">Parametrização</a>
      </tds-header-dropdown-list-item>
      <tds-header-dropdown-list-item>
        <a href="/logs">Logs de utilização</a>
      </tds-header-dropdown-list-item>
    </tds-header-dropdown-list>
  </tds-header-dropdown>
  <tds-header-dropdown>
    <span slot="label">Cartão de Crédito</span>
    <tds-header-dropdown-list>
      <tds-header-dropdown-list-item>
        <a href="/cartao-credito/carga-fatura">Carga da Fatura</a>
      </tds-header-dropdown-list-item>
      <tds-header-dropdown-list-item>
        <a href="/cartao-credito/consulta-fatura">Consulta da Fatura</a>
      </tds-header-dropdown-list-item>
      <tds-header-dropdown-list-item>
        <a href="/cartao-credito/fechamento-conciliacao">Fechamento de Conciliação</a>
      </tds-header-dropdown-list-item>
      <tds-header-dropdown-list-item>
        <a href="/cartao-credito/relatorio-conciliacao">Relatório de Conciliação</a>
      </tds-header-dropdown-list-item>
    </tds-header-dropdown-list>
  </tds-header-dropdown>
  <tds-header-dropdown>
    <span slot="label">Financeiro</span>
    <tds-header-dropdown-list>
      <tds-header-dropdown-list-item>
        <a href="/acompanhamento">Acompanhamento</a>
      </tds-header-dropdown-list-item>
    </tds-header-dropdown-list>
  </tds-header-dropdown>
  <tds-header-dropdown>
    <span slot="label">Viagens</span>
    <tds-header-dropdown-list>
      <tds-header-dropdown-list-item>
        <a href="/viagens/Notificacoes">Notificações</a>
      </tds-header-dropdown-list-item>
      <tds-header-dropdown-list-item>
        <a href="/viagens/Tiponotificacao">Tipo de Notificações</a>
      </tds-header-dropdown-list-item>
      <tds-header-dropdown-list-item>
        <a href="/viagens/Empresas">Empresas</a>
      </tds-header-dropdown-list-item>
    </tds-header-dropdown-list>
  </tds-header-dropdown>

  <tds-header-item slot="end">
    <button onclick="alert('Sair clicado')">
      <tds-icon style=" transform: rotate(-90deg);" name="download" size="20px"></tds-icon>
    </button>
  </tds-header-item>

  <tds-header-brand-symbol slot="end">
    <a aria-label="Scania - red gryphon on blue shield" href="https://scania.com"></a>
  </tds-header-brand-symbol>
</tds-header>
