import { Component, Renderer2 } from '@angular/core';
import { UserSessionInfo } from 'src/app/_models/UserSessionInfo';
import { RolesEnum } from 'src/app/_shared/roles.enum';
import { AuthService } from 'src/app/services/auth.service';
import { UserSessionInfoService } from 'src/app/services/user-session-info.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  rolesEnum: typeof RolesEnum = RolesEnum;
  userSessionInfo: UserSessionInfo | null = null;

  constructor(
    private authService: AuthService,
    private userSessionInfoService: UserSessionInfoService,
    private render: Renderer2
  ) {

  }

  ngOnInit(): void {
    this.userSessionInfoService.userSessionInfo$.subscribe((userSessionInfo) => {
      this.userSessionInfo = userSessionInfo;
    });
  }

  checkUserRoles(roles: string[]): boolean {
    return this.authService.checkUserRoles(roles);
  }


  sair(): void {
    this.authService.logout();
  }

}
