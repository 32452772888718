<tds-header>
  <!-- TODO setting aria-expanded="true" on the hamburger button does not work, as it is not
        copied to the button element -->
  <tds-header-hamburger id="demo-hamburger"
    onclick="demoSideMenu.open = true;demoHamburger.setAttribute('aria-expanded', true);"
    aria-label="Open application drawer" aria-haspopup="true" aria-expanded="false"></tds-header-hamburger>

  <tds-header-title><a routerLink="/home">STEXA</a></tds-header-title>

  <tds-header-dropdown
    *ngIf="checkUserRoles([
      rolesEnum.AP4500001_Setup_Finance,
      rolesEnum.AP4500001_Accounting_Setup,
      rolesEnum.AP4500001_Setup_Users_Read_Only,
      rolesEnum.AP4500001_Conciliation_Credit_Card_Superuser,
      rolesEnum.AP4500001_Conciliation_Credit_Card_Users_read_only,
      rolesEnum.AP4500001_Conciliation_Credit_Card_User_Mng_read_only,
      rolesEnum.AP4500001_Accounting_Setup])">
    <span slot="label">Contábil</span>
    <tds-header-dropdown-list>
      <tds-header-dropdown-list-item
        *ngIf="checkUserRoles([rolesEnum.AP4500001_Setup_Finance, rolesEnum.AP4500001_Setup_Users_Read_Only, rolesEnum.AP4500001_Accounting_Setup])">
        <a routerLink="/parametrizacao">Parametrização</a>
      </tds-header-dropdown-list-item>
      <tds-header-dropdown-list-item
        *ngIf="checkUserRoles([rolesEnum.AP4500001_Accounting_Setup, rolesEnum.AP4500001_Setup_Users_Read_Only])">
        <a routerLink="/logs">Logs de utilização</a>
      </tds-header-dropdown-list-item>
      <tds-header-dropdown-list-item *ngIf="checkUserRoles([rolesEnum.AP4500001_Credit_Card_Bill_Superuser])">
        <a routerLink="/cartao-credito/consulta-fatura">Fatura do Cartão</a>
      </tds-header-dropdown-list-item>
      <tds-header-dropdown-list-item *ngIf="checkUserRoles([rolesEnum.AP4500001_Conciliation_Credit_Card_Superuser])">
        <a routerLink="/cartao-credito/fechamento-conciliacao">Fechamento de Conciliação</a>
      </tds-header-dropdown-list-item>
      <tds-header-dropdown-list-item *ngIf="checkUserRoles([rolesEnum.AP4500001_Conciliation_Credit_Card_Users_read_only, rolesEnum.AP4500001_Conciliation_Credit_Card_User_Mng_read_only])">
        <a routerLink="/cartao-credito/relatorio-conciliacao">Relatório de Conciliação</a>
      </tds-header-dropdown-list-item>
    </tds-header-dropdown-list>
  </tds-header-dropdown>
  <tds-header-dropdown
    *ngIf="checkUserRoles([
      rolesEnum.AP4500001_Finance_Flags_superuser,
      rolesEnum.AP4500001_Finance_FollowUp_read_only
    ])">
    <span slot="label">Financeiro</span>
    <tds-header-dropdown-list>
      <tds-header-dropdown-list-item *ngIf="checkUserRoles([rolesEnum.AP4500001_Finance_Flags_superuser, rolesEnum.AP4500001_Finance_FollowUp_read_only])">
        <a routerLink="/acompanhamento">Acompanhamento</a>
      </tds-header-dropdown-list-item>
    </tds-header-dropdown-list>
  </tds-header-dropdown>
  <tds-header-dropdown
    *ngIf="checkUserRoles([
      rolesEnum.AP4500001_Credit_Card_Bill_Superuser,
      rolesEnum.AP4500001_Credit_card_Bill_Read_only,
      rolesEnum.AP4500001_Card_Manage_Super_User,
      rolesEnum.AP4500001_Travel_super_user])">
    <span slot="label">Cartão Corporativo</span>
    <tds-header-dropdown-list>
      <tds-header-dropdown-list-item *ngIf="checkUserRoles([rolesEnum.AP4500001_Card_Manage_Super_User,rolesEnum.AP4500001_Travel_super_user])"> <!---------------------------fica------------------------------------->
        <a routerLink="/cartoes/parametrizacao-cartoes">Parametrização de Cartões</a>
      </tds-header-dropdown-list-item>
      <tds-header-dropdown-list-item *ngIf="checkUserRoles([rolesEnum.AP4500001_Card_Manage_Super_User, rolesEnum.AP4500001_Travel_super_user])">
        <a routerLink="/cartoes/controle-cartoes">Controle de Solicitação de Cartões</a> <!---------------------------fica------------------------------------->
      </tds-header-dropdown-list-item>
      <tds-header-dropdown-list-item *ngIf="checkUserRoles([rolesEnum.AP4500001_Conciliation_Credit_Card_Users_read_only, rolesEnum.AP4500001_Conciliation_Credit_Card_User_Mng_read_only, rolesEnum.AP4500001_Travel_super_user])">
        <a routerLink="/cartoes/cartoescorporativos-cartoes">Cartões Corporativos</a> <!---------------------------fica------------------------------------->
      </tds-header-dropdown-list-item>      
    </tds-header-dropdown-list>
  </tds-header-dropdown>
  <tds-header-dropdown
    *ngIf="checkUserRoles([
      rolesEnum.AP4500001_Travel_super_user,
      rolesEnum.AP4500001_Notification_Read_Only,
      rolesEnum.AP4500001_Notification_Super_User
    ])">
    <span slot="label">Viagens</span>
    <tds-header-dropdown-list>
      <tds-header-dropdown-list-item *ngIf="checkUserRoles([
        rolesEnum.AP4500001_Notification_Super_User, 
        rolesEnum.AP4500001_Notification_Read_Only,
        rolesEnum.AP4500001_Travel_super_user])">
        <a routerLink="/assessment-viagens/assessement-viagens-consulta">Consulta Assessment</a>
      </tds-header-dropdown-list-item>
      <tds-header-dropdown-list-item *ngIf="checkUserRoles([rolesEnum.AP4500001_Travel_super_user])">
        <a routerLink="/notificacao-parametrizacao/Notificacoes">Notificações</a>
      </tds-header-dropdown-list-item>
      <tds-header-dropdown-list-item *ngIf="checkUserRoles([rolesEnum.AP4500001_Travel_super_user])">
        <a routerLink="/notificacao-parametrizacao/TipoNotificacao">Tipo de Notificações</a>
      </tds-header-dropdown-list-item>

      <tds-header-dropdown-list-item *ngIf="checkUserRoles([rolesEnum.AP4500001_Travel_super_user])">
        <a routerLink="/notificacao-parametrizacao/Empresas">Empresas</a>
      </tds-header-dropdown-list-item>
      <tds-header-dropdown-list-item *ngIf="checkUserRoles([rolesEnum.AP4500001_Travel_super_user])">
        <a routerLink="/notificacao-relatorios/relatorio-viagens-consulta">Relatório de Viagens</a>
      </tds-header-dropdown-list-item>
    </tds-header-dropdown-list>
  </tds-header-dropdown>

  <!-- Menus do Usuário -->

  <!-- Menu Colaborador -->
  <tds-header-dropdown *ngIf="checkUserRoles([rolesEnum.AP4500001_Users_Read_Only, rolesEnum.AP4500001_Card_Manage_Super_User, rolesEnum.AP4500001_Travel_super_user])">
    <span slot="label">Colaborador</span>
    <tds-header-dropdown-list>
      <tds-header-dropdown-list-item *ngIf="checkUserRoles([rolesEnum.AP4500001_Users_Read_Only])">
        <a routerLink="/usuario/funcionario-consulta-financeiro">Acompanhamento Financeiro</a>
      </tds-header-dropdown-list-item>
    </tds-header-dropdown-list>
    <tds-header-dropdown-list-item *ngIf="checkUserRoles([rolesEnum.AP4500001_Card_Manage_Super_User, rolesEnum.AP4500001_Travel_super_user])">
      <a routerLink="/cartoes/solicitacao-cartoes">Solicitação de Cartões</a>
    </tds-header-dropdown-list-item>
  </tds-header-dropdown>

  <tds-header-item slot="end">
    <!-- Workaround para estilizar a exibição abaixo aproveitando a configuração do componente; -->
    <a style="pointer-events: none;">
      <span>Seja bem vindo(a),
        <br>{{userSessionInfo?.name}}
      </span>
    </a>
  </tds-header-item>

  <tds-header-item slot="end">
    <button (click)="sair()" title="Efetuar logoff da Aplicação">
      <tds-icon style=" transform: rotate(-90deg);" name="download" size="20px"></tds-icon>
    </button>
  </tds-header-item>

  <tds-header-brand-symbol slot="end">
    <a aria-label="Scania - red gryphon on blue shield" href="https://scania.com"></a>
  </tds-header-brand-symbol>
</tds-header>
