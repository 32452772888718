import { Component } from '@angular/core';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-loader',
  template: '<ngx-loading [show]="this.loaderService.loading$ | async"></ngx-loading>'
})
export class LoaderComponent {
  constructor(public loaderService: LoaderService) { }
}
