import { Injectable } from '@angular/core';
import { UserSessionInfoService } from './user-session-info.service';
import { IpinfoService } from './ipinfo.service';
import { LocalStorageService } from './local-storage.service';
import { UserLogEntry } from '../_models/UserLogEntry';

@Injectable({
  providedIn: 'root'
})
export class UserLogEntryService {
  private userLogEntryDto: UserLogEntry = null;

  constructor(
    private userSessionInfoService: UserSessionInfoService,
    private ipInfoService: IpinfoService,
    private localStorageService: LocalStorageService
  ) {}

  initializeUserLogEntryDto() {
    const decodeSessionUser = this.localStorageService.get('ipInfo');
    this.userSessionInfoService.userSessionInfo$.subscribe((userSessionInfo) => {
      this.userLogEntryDto = {
        userName: userSessionInfo.ssb,
        clientIpAddr: decodeSessionUser.ip,
        clientHostname: decodeSessionUser.region,
      };
    });
  }

  getUserLogEntryDto() {
    return this.userLogEntryDto;
  }
}
