import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  private isLoading = new BehaviorSubject<boolean>(false);

  loading$: Observable<boolean> = this.isLoading.asObservable();

  show(): void {

    this.isLoading.next(true);
    // console.log('LoadService Chamou');

 }

  hide(): void {
    this.isLoading.next(false);
    // console.log('Fechou Load');
    //this.refreshScroll();
  }

  // Ajuste temporario para o tooltip devido bug do mesmo aparece perdido quando iniciar, porem se chamar o scrol ele volta para o lugar correto.
  refreshScroll(): void {
   window.scrollTo(0, 10);
  }
}
