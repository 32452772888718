
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxLoadingModule } from 'ngx-loading';

import { LoaderService } from 'src/app/services/loader.service';
import { LoaderComponent } from 'src/app/Components/loader/loader.component';

@NgModule({
  imports: [
    CommonModule,
    NgxLoadingModule.forRoot({
      fullScreenBackdrop: true
    })
  ],
  declarations: [LoaderComponent],
  exports: [LoaderComponent],
  providers: [LoaderService]
})
export class LoaderModule { }
