<tds-header>
  <!-- TODO setting aria-expanded="true" on the hamburger button does not work, as it is not
        copied to the button element -->
  <tds-header-hamburger id="demo-hamburger"
    onclick="demoSideMenu.open = true;demoHamburger.setAttribute('aria-expanded', true);"
    aria-label="Open application drawer" aria-haspopup="true" aria-expanded="false"></tds-header-hamburger>

  <tds-header-title><a routerLink="/home">STEXA</a></tds-header-title>

  <tds-header-dropdown 
    *ngIf="checkUserRoles([
      rolesEnum.AP4500001_Setup_Finance,
      rolesEnum.AP4500001_Accounting_Setup, 
      rolesEnum.AP4500001_Setup_Users_Read_Only, 
      rolesEnum.AP4500001_Accounting_Setup])">
    <span slot="label">Contábil</span>
    <tds-header-dropdown-list>
      <tds-header-dropdown-list-item
        *ngIf="checkUserRoles([rolesEnum.AP4500001_Setup_Finance, rolesEnum.AP4500001_Setup_Users_Read_Only, rolesEnum.AP4500001_Accounting_Setup])">
        <a routerLink="/parametrizacao">Parametrização</a>
      </tds-header-dropdown-list-item>
      <tds-header-dropdown-list-item
        *ngIf="checkUserRoles([rolesEnum.AP4500001_Accounting_Setup, rolesEnum.AP4500001_Setup_Users_Read_Only])">
        <a routerLink="/logs">Logs de utilização</a>
      </tds-header-dropdown-list-item>
    </tds-header-dropdown-list>
  </tds-header-dropdown>
  <tds-header-dropdown
    *ngIf="checkUserRoles([
      rolesEnum.AP4500001_Credit_Card_Bill_Superuser, 
      rolesEnum.AP4500001_Credit_card_Bill_Read_only, 
      rolesEnum.AP4500001_Conciliation_Credit_Card_Superuser, 
      rolesEnum.AP4500001_Conciliation_Credit_Card_Users_read_only, 
      rolesEnum.AP4500001_Conciliation_Credit_Card_User_Mng_read_only])">
    <span slot="label">Cartão de Crédito</span>
    <tds-header-dropdown-list>
      <tds-header-dropdown-list-item *ngIf="checkUserRoles([rolesEnum.AP4500001_Credit_Card_Bill_Superuser])">
        <a routerLink="/cartao-credito/carga-fatura">Carga da Fatura</a>
      </tds-header-dropdown-list-item>
      <tds-header-dropdown-list-item *ngIf="checkUserRoles([rolesEnum.AP4500001_Credit_Card_Bill_Superuser, rolesEnum.AP4500001_Credit_card_Bill_Read_only])">
        <a routerLink="/cartao-credito/consulta-fatura">Consulta da Fatura</a>
      </tds-header-dropdown-list-item>
      <tds-header-dropdown-list-item *ngIf="checkUserRoles([rolesEnum.AP4500001_Conciliation_Credit_Card_Superuser])">
        <a routerLink="/cartao-credito/fechamento-conciliacao">Fechamento de Conciliação</a>
      </tds-header-dropdown-list-item>
      <tds-header-dropdown-list-item *ngIf="checkUserRoles([rolesEnum.AP4500001_Conciliation_Credit_Card_Users_read_only, rolesEnum.AP4500001_Conciliation_Credit_Card_User_Mng_read_only])">
        <a routerLink="/cartao-credito/relatorio-conciliacao">Relatório de Conciliação</a>
      </tds-header-dropdown-list-item>
    </tds-header-dropdown-list>
  </tds-header-dropdown>
  <tds-header-dropdown
    *ngIf="checkUserRoles([
      rolesEnum.AP4500001_Finance_Flags_superuser, 
      rolesEnum.AP4500001_Finance_FollowUp_read_only
    ])">
    <span slot="label">Financeiro</span>
    <tds-header-dropdown-list>
      <tds-header-dropdown-list-item *ngIf="checkUserRoles([rolesEnum.AP4500001_Finance_Flags_superuser, rolesEnum.AP4500001_Finance_FollowUp_read_only])">
        <a routerLink="/acompanhamento">Acompanhamento</a>
      </tds-header-dropdown-list-item>
    </tds-header-dropdown-list>
  </tds-header-dropdown>
  <tds-header-dropdown 
    *ngIf="checkUserRoles([
      rolesEnum.AP4500001_Travel_super_user
    ])">
    <span slot="label">Viagens</span>
    <tds-header-dropdown-list>
      <tds-header-dropdown-list-item *ngIf="checkUserRoles([rolesEnum.AP4500001_Travel_super_user])">
        <a routerLink="/notificacao-parametrizacao/Notificacoes">Notificações</a>
      </tds-header-dropdown-list-item>
      <tds-header-dropdown-list-item *ngIf="checkUserRoles([rolesEnum.AP4500001_Travel_super_user])">
        <a routerLink="/notificacao-parametrizacao/TipoNotificacao">Tipo de Notificações</a>
      </tds-header-dropdown-list-item>
      <tds-header-dropdown-list-item *ngIf="checkUserRoles([rolesEnum.AP4500001_Travel_super_user])">
        <a routerLink="/notificacao-parametrizacao/Empresas">Empresas</a>
      </tds-header-dropdown-list-item>
      <!-- <tds-header-dropdown-list-item>
        <a routerLink="/notificacao-relatorios/relatorio-viagens-consulta">Relatório de Viagens</a>
      </tds-header-dropdown-list-item> -->
    </tds-header-dropdown-list>
  </tds-header-dropdown>


  <!-- Menus do Usuário -->

  <!-- Menu Acompanhamento -->
  <!-- <tds-header-dropdown>
    <span slot="label">Acompanhamento</span>
    <tds-header-dropdown-list>
      <tds-header-dropdown-list-item>
        <a href="/usuario/funcionario-consulta-financeiro">Financeiro</a>
      </tds-header-dropdown-list-item>
    </tds-header-dropdown-list>
  </tds-header-dropdown> -->

  <tds-header-item slot="end">
    <button (click)="sair()">
      <tds-icon style=" transform: rotate(-90deg);" name="download" size="20px"></tds-icon>
    </button>
  </tds-header-item>

  <tds-header-brand-symbol slot="end">
    <a aria-label="Scania - red gryphon on blue shield" href="https://scania.com"></a>
  </tds-header-brand-symbol>
</tds-header>