import { Component, OnInit, Renderer2 } from '@angular/core';
import { AuthService } from './services/auth.service';
import { LoaderService } from './services/loader.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'stexa-frontend';
  isExpanded = false;

  constructor(
    private authService: AuthService,    
    private loaderService: LoaderService,
    private renderer: Renderer2
  ) { }

  ngOnInit(): void {

    this.loaderService.show();
    this.authService.configureLoginSingleSignOn()
      .then(
        (accountInfo) => {

          if (accountInfo) {
            
            this.loadInitialData();

          }
          else
            this.authService.logout();          

          this.loaderService.hide();         

        }
      )
  }

  loadInitialData(): void {

    if (window.location.host.indexOf('localhost') > -1) {

      this.renderer.setAttribute(document.body, 'data-env', 'LOCAL');

    } else if (window.location.host.indexOf('dev-') > -1) {

      this.renderer.setAttribute(document.body, 'data-env', 'DEV');

    } else if (window.location.host.indexOf('test-') > -1) {

      this.renderer.setAttribute(document.body, 'data-env', 'TEST');

    }

  }

  onExpand(): void {
    this.isExpanded = !this.isExpanded;
  }


}
