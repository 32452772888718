import { Inject, Injectable } from '@angular/core';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { AccountInfo, EventMessage, EventType, RedirectRequest } from '@azure/msal-browser';
import { catchError, filter } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ConfigurationService } from './configuration.service';
import { EnvironmentsEnum } from '../_shared/environments.enum';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  roles: string[] = [];

  constructor(
    private configurationService: ConfigurationService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalBroadcastService: MsalBroadcastService,
    private msalService: MsalService,
  ) { }


  configureLoginSingleSignOn(): Promise<AccountInfo> {
    this.roles = [];

    return new Promise((resolve, reject) => {

      //Verifica se já tem instância iniciada com informações da conta
      const accounts = this.msalService.instance.getAllAccounts();
      const hasAccountInformation = accounts.length > 0;

      console.log(`Ambiente: ${this.configurationService.getEnviroment()}`);

      //Se já tiver uma instância iniciada pega os dados da conta que já foram armazenados anteriormente
      if (hasAccountInformation) {
        const account = accounts[0];
        const idTokenClaims = account.idTokenClaims as any;

        if (!idTokenClaims) {
          console.warn('idTokenClaims não encontrado no token');
          this.logout();
          reject('idTokenClaims não encontrado no token');
          return;
        }

        const roles = idTokenClaims.roles || [];
        this.roles = roles;

        //Se o ambiente não for produção, guarda as roles no localstorage para realizar testes de usuário
        if (this.configurationService.getEnviroment() !== EnvironmentsEnum.production)
          localStorage.setItem(environment.storage.stexaRolesUsuario, roles.join(","));

        resolve(account);
      } else {

        //Se não tiver dados da conta, inicia uma nova instância do MSAL e armazena os dados da conta
        this.msalBroadcastService.msalSubject$.pipe(
          filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
          catchError((error) => {
            console.error('Erro no processo de login:', error);
            reject(error);
            return [];
          })
        ).subscribe({
          next: (res: any) => {
            if (res.payload) {
              const account = res.payload.account as AccountInfo;
              const roles = account.idTokenClaims?.roles ?? [];

              this.roles = roles;

              //Se o ambiente não for produção, guarda as roles no localstorage para realizar testes de usuário
              if (this.configurationService.getEnviroment() !== EnvironmentsEnum.production)
                localStorage.setItem(environment.storage.stexaRolesUsuario, roles.join(","));

              resolve(account);
            }
          }
        });
      }
    });
  }

  login() {
    if (this.msalGuardConfig.authRequest) {
      this.msalService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
    } else {
      this.msalService.loginRedirect();
    }
  }

  logout() {
    this.msalService.logoutRedirect().pipe(
      catchError((error) => {
        console.error('Erro no logout:', error);
        return [];
      })
    ).subscribe();
  }

  getRoles(): string[] {

    const getEnvironment = this.configurationService.getEnviroment();
    let roles = [];

    if (getEnvironment !== EnvironmentsEnum.production) {
      //Se NÃO for ambiente de produção pega as roles que foram gravadas no localstorage
      const rolesNoStorage = localStorage.getItem(environment.storage.stexaRolesUsuario);
      if (rolesNoStorage) {
        roles = rolesNoStorage.split(",");
        // console.log(`Ambiente: ${getEnvironment}. Pegou roles no localstorage`, roles);        
      }
    }
    else {
      //Se for ambiente de produção pega as roles que foram gravadas no service
      roles = this.roles;
      // console.log(`Ambiente: ${getEnvironment}. Pegou roles no service`, roles);      
    }


    return roles;

  }

  checkUserRoles(requiredRoles: string[]): boolean {

    // Obtém a lista de roles do usuário
    const userRoles = this.getRoles();

    // Verifica se há pelo menos uma role necessária presente nas roles do usuário
    const hasRole = requiredRoles.some(role => userRoles.includes(role));

    //Se não for ambiente de produção e houver uma role chamada ADMIN libera o acesso
    if (this.configurationService.getEnviroment() !== EnvironmentsEnum.production && userRoles.some(x => x === "ADMIN"))
      return true;

    return hasRole;

  }


}
