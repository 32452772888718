import { faXTwitter as fabXTwitter, faLinkedin as fabLinkedin, faInstagram as fabInstagram, faYoutube as fabYoutube,
  faFacebook as fabFacebook,
 } from '@fortawesome/free-brands-svg-icons';
import { Component } from '@angular/core';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  constructor(library: FaIconLibrary) {
    library.addIcons(fabFacebook,fabXTwitter,fabLinkedin,fabInstagram,fabYoutube);
  }
}
