import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HeaderComponent } from './Components/header/header.component';
import { AppComponent } from './app.component';
import { FooterComponent } from './Components/footer/footer.component';
import { MainComponent } from './Components/main/main.component';
import { LoaderService } from './services/loader.service';
import { LoaderInterceptor } from './services/loader.service.interceptor';
import { IpInfoComponent } from './Components/ip-info/ip-info.component';
import { LoaderModule } from 'src/modules/loader/loader.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AppRoutingModule } from './app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgxLoadingModule } from "ngx-loading";
import { FormsModule } from '@angular/forms';
import { DatePipe, registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { MsalGuard, MsalModule, MsalRedirectComponent } from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';

registerLocaleData(localePt);

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    MainComponent,
    IpInfoComponent
  ],
  imports: [
    AppRoutingModule,
    FontAwesomeModule,
    FormsModule,
    HttpClientModule,
    LoaderModule,
    BrowserModule,
    NgxLoadingModule.forRoot({}),
    MsalModule.forRoot(
      new PublicClientApplication({
        auth: {
          clientId: environment.clientId, // Application (client) ID from the app registration
          authority:
            environment.authUrl, // The Azure cloud instance and the app's sign-in audience (tenant ID, common, organizations, or consumers)
          redirectUri: environment.redirect, // This is your redirect URI
        },
        cache: {
          cacheLocation: "localStorage",
          storeAuthStateInCookie: isIE, // Set to true for Internet Explorer 11
        },
      }),
      {
        interactionType: InteractionType.Redirect, // MSAL Guard Configuration
        authRequest: {
          scopes: [environment.interceptor],
        }
      },
      {
        interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
        protectedResourceMap: new Map([
          [environment.apiUrl, [environment.interceptor]], // Aqui sempre que for utilizar uma api nova deve colocar ['url da api', [interceptor]]
        ]),
      },
    ),
  ],
  providers: [
    LoaderService,
    DatePipe,
    {
    provide: HTTP_INTERCEPTORS,
    useClass: LoaderInterceptor,
    multi: true,
    },
    { provide: LOCALE_ID, useValue: 'pt-BR' }, 
    //todo: quando implementar o token na API deve descomentar esse código
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: MsalInterceptor,
    //   multi: true,
    // },
    MsalGuard,
  ],
  bootstrap: [
    AppComponent,
    MsalRedirectComponent  
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class AppModule { }
