import { LocalStorageService } from './local-storage.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, of, retry, throwError } from 'rxjs';
import { IPinfo } from '../_models/IPinfo';
import { ActivatedRouteSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class IpinfoService {

  private apiUrl = 'https://ipinfo.io';
  private ipinfo = new BehaviorSubject<IPinfo>(null);

  constructor(private http: HttpClient, private LocalService: LocalStorageService) {}

  // public async getIpInfo(): Promise<Observable<IPinfo>> {
  //     return await this.http.get<IPinfo>(`${this.apiUrl}/json`)
  //     .pipe(
  //       tap(iPinfo => {
  //         console.log(iPinfo, "Dentro da Service");
  //         this.LocalService.set('ipInfo', iPinfo);
  //       }),
  //       retry(2),
  //       catchError(this.handleError))
  // }

  Ipinfo$: Observable<IPinfo> = this.ipinfo.asObservable();

   getIpiInfo():  Observable<IPinfo> {
    return this.http.get<IPinfo>(`${this.apiUrl}/json`)
      .pipe(
        retry(2),
        catchError(this.handleError));
  }

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    // console.log('Called Get Product in resolver...', route);
    return this.getIpiInfo().pipe(
      catchError(error => {
        console.log('IpInfo Error; ', error);
        return of('No data');
      })
    );
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    console.log(error);
    if (error.error instanceof ErrorEvent) {
      // Erro ocorreu no lado do client
      errorMessage = error.error.message;
    } else {
      // Erro ocorreu no lado do servidor
      errorMessage = `Código do erro: ${error.status}, ` + `mensagem: ${error.message}`;
    }
    return throwError(errorMessage);
  }

}
